import(/* webpackMode: "eager" */ "/vercel/path0/apps/url-proxy/src/app/[lang]/(website)/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeaturesSection"] */ "/vercel/path0/apps/url-proxy/src/app/[lang]/(website)/components/sections/features/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/vercel/path0/apps/url-proxy/src/app/[lang]/(website)/components/sections/hero/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/url-proxy/src/app/[lang]/(website)/components/sections/testimonials/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/url-proxy/src/components/pricing/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/apps/url-proxy/src/modules/lp/components/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/url-proxy/src/modules/lp/sections/faqs/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
