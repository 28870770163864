"use client";

/* eslint-disable @next/next/no-img-element */

import { SECTION_IDS } from "modules/lp/contants";

import { useEffect, useMemo, useState } from "react";
import TestimonialCard from "./components/Card";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@irbano/react-components/dist/components/ui/carousel";
import { cn } from "@irbano/react-components/dist/lib/utils";
import { LampContainer } from "../../lamp";
import MotionLayout from "layouts/dashboard/MotionLayout";

export type TestimonialsSectionTranslations = {
  testimonialsSection: {
    "01": {
      text: string;
      name: string;
      title: string;
    };
    "02": {
      text: string;
      name: string;
      title: string;
    };
  };
};

export type TestimonialsSectionProps = {
  translations: TestimonialsSectionTranslations;
};

export default function TestimonialsSection({
  translations,
}: TestimonialsSectionProps) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const TESTIMONIALS = useMemo(() => {
    return [
      {
        text: translations.testimonialsSection["01"].text,
        name: translations.testimonialsSection["01"].name,
        title: translations.testimonialsSection["01"].title,
        photo: "/lp/man-mock.jpg",
      },
      {
        text: translations.testimonialsSection["02"].text,
        name: translations.testimonialsSection["02"].name,
        title: translations.testimonialsSection["02"].title,
        photo: "/lp/testimonials/mock.jpg",
      },
    ];
  }, [translations]);

  return (
    <MotionLayout className="relative flex flex-col gap-4 items-center justify-center w-full">
      <section
        id={SECTION_IDS.testimonials}
        className="w-full pb-8 pt-24 sm:pb-24 bg-slate-950  flex flex-col items-center"
      >
        <Carousel
          setApi={setApi}
          orientation="horizontal"
          className="w-full max-w-full sm:max-w-96 md:max-w-screen-sm px-6"
        >
          <CarouselContent className="p-1">
            {TESTIMONIALS.map(({ name, text, title }, index) => {
              return (
                <CarouselItem key={name} className="pl-4 lg:basis-full">
                  <LampContainer>
                    <TestimonialCard
                      key={index}
                      name={name}
                      text={text}
                      title={title}
                      className={cn(
                        "h-80",
                        index === current - 1 ? "opacity-100" : "opacity-50",
                        index === count - 1 ? "mr-8" : "mr-0"
                      )}
                    />
                  </LampContainer>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <div className="bg-red-400 absolute z-50 left-1/2">
            <CarouselPrevious
              id={"testimonials-section-go-to-previous-button"}
            />
            <CarouselNext id={"testimonials-section-go-to-next-button"} />
          </div>
        </Carousel>
      </section>
    </MotionLayout>
  );
}
