"use client";

import React from "react";
import { HeroHighlight, Highlight } from "../heroHighLight";
import { motion } from "framer-motion";

type HighlightText = {
  text: string;
};

export const HighlightText = ({ text }: HighlightText) => {
  return (
    <HeroHighlight>
      <motion.span
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={{
          opacity: 1,
          y: [20, -5, 0],
        }}
        transition={{
          duration: 0.5,
          ease: [0.4, 0.0, 0.2, 1],
        }}
        // className="text-2xl py-2 font-bold text-secondary"
      >
        <Highlight className="text-nowrap">{text}</Highlight>
      </motion.span>
    </HeroHighlight>
  );
};

export default HighlightText;
