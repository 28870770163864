"use client";

import { LazyMotion, domAnimation, m, MotionProps } from "framer-motion";
import useScreenSize from "shared/hooks/useScreenSize";

export type MotionLayoutProps = {
  children: React.ReactNode;
  motionProps?: MotionProps;
  className?: string | undefined;
};

const MotionLayout = ({
  children,
  motionProps,
  className,
}: MotionLayoutProps) => {
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return <div className={className}>{children}</div>;
  }

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={motionProps?.initial ?? { opacity: 0.0, y: 40 }}
        whileInView={motionProps?.whileInView ?? { opacity: 1, y: 0 }}
        transition={
          motionProps?.transition ?? {
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }
        }
        className={className}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
};

export default MotionLayout;
