"use client";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { useMemo } from "react";
import { SECTION_IDS } from "modules/lp/contants";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";

export type FaqsSectionTranslations = {
  faqsSection: {
    title: string;
    questions: {
      "01": {
        question: string;
        answer: string;
      };
      "02": {
        question: string;
        answer: string;
      };
      "03": {
        question: string;
        answer: string;
      };
      "04": {
        question: string;
        answer: string;
      };
    };
  };
};

export type FaqsSectionProps = {
  translation: TranslationProp<FaqsSectionTranslations>;
};
export default function FaqsSection({ translation }: FaqsSectionProps) {
  const { t } = useTranslation({ translation });

  const FAQS = useMemo(() => {
    return [
      {
        question: t("faqsSection.questions.01.question"),
        answer: t("faqsSection.questions.01.answer"),
      },
      {
        question: t("faqsSection.questions.02.question"),
        answer: t("faqsSection.questions.02.answer"),
      },
      {
        question: t("faqsSection.questions.03.question"),
        answer: t("faqsSection.questions.03.answer"),
      },
      {
        question: t("faqsSection.questions.04.question"),
        answer: t("faqsSection.questions.04.answer"),
      },
    ];
  }, [t]);

  return (
    <div id={SECTION_IDS.faq} className="w-full bg-slate-950">
      <div className="w-full mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="w-full mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-xl font-bold leading-10 tracking-tight text-white">
            {t("faqsSection.title")}
          </h2>
          <dl className="w-full mt-10 space-y-6 divide-y divide-white/10">
            {FAQS.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="w-full">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-[0.9rem] leading-7 text-gray-300">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
