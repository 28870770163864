import { cn } from "@irbano/react-components/dist/lib/utils";
import LinkWithArrow from "modules/lp/components/LinkWithArrow";
import React, { ReactNode } from "react";

export type FeatureCardProps = {
  id: string;
  name: string;
  description: string;
  learnMoreHref?: string;
  Icon?: (props: any) => ReactNode;
  className?: string;
};
export const FeatureCard = ({
  id,
  name,
  description,
  learnMoreHref,
  Icon,
  className,
}: FeatureCardProps) => {
  return (
    <div
      id={id}
      className={cn(
        "group flex flex-col bg-white rounded-md p-4 shadow-md hover:bg-primary-300 hover:text-white",
        className
      )}
    >
      <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
        {Icon ? <Icon /> : null}
        {name}
      </dt>
      <dd className="mt-4 flex flex-auto flex-col">
        <p className="flex-auto text-[0.9rem] lg:text-base text-gray-600 group-hover:text-gray-800">
          {description}
        </p>
        {learnMoreHref ? (
          <LinkWithArrow
            href={learnMoreHref}
            text="Learn more"
            className="mt-4 text-sm"
          />
        ) : null}
      </dd>
    </div>
  );
};
