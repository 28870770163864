"use client";
import { ReactNode } from "react";
import { FeatureCard } from "./FeatureCard";
import { cn } from "utils/cn";
import MotionLayout from "layouts/dashboard/MotionLayout";

export type FeaturesSectionTranslations = {
  contentSection: {
    smallTitle: string;
    title: string;
    subTitle: string;
  };
  features: {
    openGraph: {
      title: string;
      description: string;
    };
    editLinkDestinations: {
      title: string;
      description: string;
    };
    destinationsPerLink: {
      title: string;
      description: string;
    };
    customDomains: {
      title: string;
      description: string;
    };
    workspaces: {
      title: string;
      description: string;
    };
    customShortId: {
      title: string;
      description: string;
    };
    deepLink: {
      title: string;
      description: string;
    };
    utmBuilder: {
      title: string;
      description: string;
    };
    queryParamsProxy: {
      title: string;
      description: string;
    };
    shareWithQRCode: {
      title: string;
      description: string;
    };

    linkAnalytics: {
      title: string;
      description: string;
    };
  };
};
export type FeaturesSectionProps = {
  translations: FeaturesSectionTranslations;
};

export type FeatureItem = {
  id: string;
  name: string;
  description: string;
  learnMoreHref?: string;
  Icon?: (props: any) => ReactNode;
};

export const FeaturesSection = ({ translations }: FeaturesSectionProps) => {
  const features: FeatureItem[] = [
    {
      id: "openGraph",
      name: translations.features.openGraph.title,
      description: translations.features.openGraph.description,
    },
    {
      id: "deepLink",
      name: translations.features.deepLink.title,
      description: translations.features.deepLink.description,
    },
    {
      id: "destinationsPerLink",
      name: translations.features.destinationsPerLink.title,
      description: translations.features.destinationsPerLink.description,
    },
    {
      id: "utmBuilder",
      name: translations.features.utmBuilder.title,
      description: translations.features.utmBuilder.description,
    },
    {
      id: "editLinkDestinations",
      name: translations.features.editLinkDestinations.title,
      description: translations.features.editLinkDestinations.description,
    },
    {
      id: "workspaces",
      name: translations.features.workspaces.title,
      description: translations.features.workspaces.description,
    },
    {
      id: "linkAnalytics",
      name: translations.features.linkAnalytics.title,
      description: translations.features.linkAnalytics.description,
    },
    {
      id: "shareWithQRCode",
      name: translations.features.shareWithQRCode.title,
      description: translations.features.shareWithQRCode.description,
    },
    {
      id: "customShortId",
      name: translations.features.customShortId.title,
      description: translations.features.customShortId.description,
    },
    {
      id: "customDomains",
      name: translations.features.customDomains.title,
      description: translations.features.customDomains.description,
    },
  ];

  return (
    <MotionLayout className="relative flex flex-col gap-4 items-center justify-center">
      <section
        id="features"
        className="flex flex-col justify-center items-center w-full px-4 md:px-8 bg-white py-12"
      >
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base text-center font-semibold leading-7 text-primary-500">
            {translations.contentSection.smallTitle}
          </h2>
          <p className="mt-2 text-xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
            {translations.contentSection.title}
          </p>
          <p className="mt-6 text-base text-justify leading-8 text-gray-600">
            {translations.contentSection.subTitle}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center mt-4 max-w-screen-sm lg:max-w-screen-lg">
          {features.map((feature) => (
            <FeatureCard
              key={`FeatureCard_${feature.name}`}
              {...feature}
              className={cn("w-full p-4")}
            />
          ))}
        </div>
      </section>
    </MotionLayout>
  );
};
