"use client";

import Link, { LinkProps } from "next/link";
import React from "react";

import ArrowNarrowRightIcon from "@heroicons/react/outline/ArrowNarrowRightIcon";
import { cn } from "@irbano/react-components/dist/lib/utils";
import { Url } from "next/dist/shared/lib/router/router";

export type LinkWithArrowProps = Omit<LinkProps, "href" | "className"> & {
  text: string;
  href: Url;
  className?: string;
  id?: string;
};
const LinkWithArrow = ({
  text,
  href,
  className,
  ...props
}: LinkWithArrowProps) => {
  return (
    <Link
      className={cn("w-fit flex justify-center items-center gap-2", className)}
      href={href}
      {...props}
    >
      <p className="font-semibold text-nowrap">{text}</p>
      <ArrowNarrowRightIcon className="w-4" />
    </Link>
  );
};

export default LinkWithArrow;
