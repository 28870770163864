/* eslint-disable @next/next/no-img-element */
"use client";

import { SECTION_IDS } from "modules/lp/contants";
import Link from "next/link";
import dynamic from "next/dynamic";
import HighlightText from "../../highlightText";

import MotionLayout from "layouts/dashboard/MotionLayout";
import useScreenSize from "shared/hooks/useScreenSize";

const LottieAnimation = dynamic(() =>
  import("./lottie").then((m) => m.LottieAnimation)
);

export type HeroSectionTranslations = {
  heroSection: {
    title: string;
    signUp: string;
    seeMore: string;
    discover: string;
    makeA: string;
    smart: string;
    multifunctional: string;
    linkWith: string;
    endToEnd: string;
    tracking: string;
  };
};
export type HeroSectionProps = {
  translation: HeroSectionTranslations;
};

export const HeroSection = ({ translation }: HeroSectionProps) => {
  const {
    title,
    signUp,
    seeMore,
    endToEnd,
    linkWith,
    makeA,
    multifunctional,
    smart,
    tracking,
    discover,
  } = translation.heroSection;

  const { isMobile } = useScreenSize();

  return (
    <main
      id={SECTION_IDS.home}
      className="w-full min-h-[60vh] flex justify-center items-center "
    >
      <MotionLayout className="  relative flex flex-col gap-4 items-center justify-center">
        <div className=" relative w-full isolate overflow-hidden">
          <div className="w-full max-w-7xl sm:pb-32 lg:flex lg:px-8 ">
            <div className="w-full lg:w-1/2 max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl px-6 ">
              <div className="mt-8 lg:mt-16">
                <Link
                  id="hero-section-title-button"
                  href="#features"
                  className="inline-flex space-x-6"
                >
                  <span className="rounded-full bg-primary mt-2 px-3 py-1 text-sm font-semibold leading-6 text-secondary-800  ring-1 ring-inset ring-indigo-500/20">
                    {title}
                  </span>
                </Link>
              </div>
              <div className="hidden text-xl md:text-2xl md:flex flex-col mt-4 font-bold py-2 space-y-2 lg:text-nowrap">
                <div className="w-full flex space-x-1  whitespace-nowrap">
                  <span>{makeA}</span>
                  <HighlightText text={smart} />,
                  <HighlightText text={multifunctional} />
                </div>

                <div className="w-full flex space-x-1">
                  <span>{linkWith}</span>
                  <HighlightText text={endToEnd} />
                  <span>{tracking}</span>
                </div>
              </div>

              <div className="w-full mt-3 md:hidden">
                <span className="text-center text-2xl leading-8 font-semibold text-secondary">{`${makeA} ${smart} ${multifunctional} ${linkWith} ${endToEnd} ${tracking}`}</span>
              </div>

              <p className="mt-6 text-[0.9rem] lg:text-lg leading-8 text-secondary">
                {signUp}
              </p>
              <div className="mt-10 flex flex-col justify-center  items-center md:items-start">
                <div className="w-auto text-center flex flex-col justify-center items-center">
                  <Link
                    id="hero-section-plans-button"
                    href="#plans"
                    className="rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-primary shadow-sm hover:bg-secondary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 hover:scale-105 transition-transform duration-300 ease-in-out"
                  >
                    {seeMore}
                  </Link>
                  <Link
                    id="hero-section-features-button-2"
                    className="text-sm py-2 hover:underline  font-semibold"
                    href="#features"
                    scroll={true}
                  >
                    {discover}
                  </Link>
                </div>
              </div>
            </div>
            {isMobile ? null : (
              <div className="w-full lg:w-1/2 flex items-center justify-center">
                <LottieAnimation />
              </div>
            )}
          </div>
        </div>
      </MotionLayout>
    </main>
  );
};
