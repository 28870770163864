import { useEffect, useState } from "react";

export default function useScreenSize() {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      const innerWidth = window.innerWidth;
      setWidth(innerWidth);
    });
  }, []);

  return { isMobile: width < 1024 };
}
