import React from "react";
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft";

// import { Container } from './styles';

export type TestimonialCardProps = {
  name: string;
  text: string;
  title: string;
  className?: string;
};

const TestimonialCard = ({ name, text, title }: TestimonialCardProps) => {
  return (
    <li className="w-full max-w-full relative  flex-shrink-0   list-none">
      <blockquote>
        <div
          aria-hidden="true"
          className="user-select-none -z-1 pointer-events-none absolute -left-0.5 -top-0.5 h-[calc(100%_+_4px)] w-[calc(100%_+_4px)]"
        ></div>
        <span className=" relative z-20 text-normal font-[500] leading-[1.6] text-gray-100 ">
          <ImQuotesLeft size={28} className="mb-3" />
          {text}
        </span>
        <div className="relative z-20 mt-6 flex flex-row items-center justify-end">
          <span className="flex flex-col gap-1">
            <span className=" text-sm leading-[1.6] text-gray-400 font-normal">
              {name}
            </span>
            <span className=" text-sm leading-[1.6] text-gray-400 font-normal w-full text-justify">
              {title}
            </span>
          </span>
        </div>
      </blockquote>
    </li>
  );
};

export default TestimonialCard;
